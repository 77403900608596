// eslint-disable-next-line no-restricted-imports
import pkg from '../../package.json';

export const APP_DOMAIN = 'videmix.com';
export const APP_URL = `https://www.${APP_DOMAIN}`;
export const APP_VERSION = pkg.version || '1.0.0';

// export const GOOGLE_AUTH_ID = defined(process.env.NEXT_PUBLIC_GOOGLE_AUTH_ID, 'GOOGLE_AUTH_ID');
// export const GOOGLE_AUTH_SECRET = defined(process.env.NEXT_PUBLIC_GOOGLE_AUTH_SECRET, 'GOOGLE_AUTH_SECRET');

export const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID;
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const SENTRY_DNS = process.env.SENTRY_DNS;
// export const AMPLITUDE_API_KEY = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
